import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, useMap, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Tabs } from 'antd';

const Map = ({ geoJson, CogUrl }) => {
  const [rasterLayerUrl, setRasterLayerUrl] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [modalProperties, setModalProperties] = useState(null)

  useEffect(() => {
    if (!CogUrl) return
    const layerURL = "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tilejson.json"
    const statsURL = "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics"
    const colorMapName = "viridis"

    fetch((`${statsURL}?url=${CogUrl}`)).then((statsResponse) => {
      if (statsResponse.status === 200) {
        statsResponse.json().then((stats) => {
          fetch(`${layerURL}?url=${CogUrl}&rescale=${stats?.b1?.min},${stats?.b1?.max}&colormap_name=${colorMapName}`).then((response) => {
            if (!statsResponse.status === 200) return
            response.json().then((data) => {
              if (!data) {
                return console.log("Cog data not found")
              }
              setRasterLayerUrl(data.tiles[0])
              setBounds([[data.bounds[1], data.bounds[0]], [data.bounds[3], data.bounds[2]]])
            })

          }).catch((apiError) => {
            console.log("apiError", apiError)
          })
        })
      }

    })
  }, [CogUrl])

  useEffect(() => {
    if (geoJson) {
      console.log("geoJson", L.geoJSON(geoJson).getBounds())
      setBounds(L.geoJSON(geoJson).getBounds())
    }
  }, [geoJson])

  useEffect(() => {
    if (geoJson) {
      console.log("geoJson", L.geoJSON(geoJson).getBounds())
      setBounds(L.geoJSON(geoJson).getBounds())
    }
  }, [])

  const options = {
    grid: {
      show: true,
      borderColor: '#cadbdb',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        },

      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: undefined,
        opacity: 0.1
      },
      column: {
        colors: undefined,
        opacity: 0.1
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },
    chart: {
      height: 500,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
    xaxis: {
      type: 'line',
      categories: modalProperties?.maxset.map((item) => item?.time),
      tickAmount: 36,
    },
    yaxis: {
      min: -1,
      max: 1,
      tickAmount: undefined
    },
    tooltip: {
      x: {
        format: 'YYYY-mm-dd'
      },
    },
  }

  const maxseries = [
    {
      name: 'NDVI',
      data: modalProperties?.maxset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
    {
      name: 'LSWI',
      data: modalProperties?.maxset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
    {
      name: 'NDRE',
      data: modalProperties?.maxset.map((item) => item?.NDRE?.toFixed(2) || 0)
    }
  ]
  const minseries = [
    {
      name: 'NDVI',
      data: modalProperties?.minset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
    {
      name: 'LSWI',
      data: modalProperties?.minset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
    {
      name: 'NDRE',
      data: modalProperties?.minset.map((item) => item?.NDRE?.toFixed(2) || 0)
    }
  ]
  const meanseries = [
    {
      name: 'NDVI',
      data: modalProperties?.meanset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
    {
      name: 'LSWI',
      data: modalProperties?.meanset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
    {
      name: 'NDRE',
      data: modalProperties?.meanset.map((item) => item?.NDRE?.toFixed(2) || 0)
    }
  ]
  const stdseries = [
    {
      name: 'NDVI',
      data: modalProperties?.stdset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
    {
      name: 'LSWI',
      data: modalProperties?.stdset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
    {
      name: 'NDRE',
      data: modalProperties?.stdset.map((item) => item?.NDRE?.toFixed(2) || 0)
    }
  ]

  const CIG = [
    {
      name: 'Min',
      data: modalProperties?.minset.map((item) => item?.CIG?.toFixed(2) || 0)
    },
    {
      name: 'Mean',
      data: modalProperties?.meanset.map((item) => item?.CIG?.toFixed(2) || 0)
    },
    {
      name: 'Max',
      data: modalProperties?.maxset.map((item) => item?.CIG?.toFixed(2) || 0)
    },
    {
      name: 'SD',
      data: modalProperties?.stdset.map((item) => item?.CIG?.toFixed(2) || 0)
    },
  ]

  const CIRedEdge = [
    {
      name: 'Min',
      data: modalProperties?.minset.map((item) => item?.CIRedEdge?.toFixed(2) || 0)
    },
    {
      name: 'Mean',
      data: modalProperties?.meanset.map((item) => item?.CIRedEdge?.toFixed(2) || 0)
    },
    {
      name: 'Max',
      data: modalProperties?.maxset.map((item) => item?.CIRedEdge?.toFixed(2) || 0)
    },
    {
      name: 'SD',
      data: modalProperties?.stdset.map((item) => item?.CIRedEdge?.toFixed(2) || 0)
    },
  ]

  const LSWI = [
    {
      name: 'Min',
      data: modalProperties?.minset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
    {
      name: 'Mean',
      data: modalProperties?.meanset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
    {
      name: 'Max',
      data: modalProperties?.maxset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
    {
      name: 'SD',
      data: modalProperties?.stdset.map((item) => item?.LSWI?.toFixed(2) || 0)
    },
  ]

  const NDRE = [
    {
      name: 'Min',
      data: modalProperties?.minset.map((item) => item?.NDRE?.toFixed(2) || 0)
    },
    {
      name: 'Mean',
      data: modalProperties?.meanset.map((item) => item?.NDRE?.toFixed(2) || 0)
    },
    {
      name: 'Max',
      data: modalProperties?.maxset.map((item) => item?.NDRE?.toFixed(2) || 0)
    },
    {
      name: 'SD',
      data: modalProperties?.stdset.map((item) => item?.NDRE?.toFixed(2) || 0)
    },
  ]

  const NDWI = [
    {
      name: 'Min',
      data: modalProperties?.minset.map((item) => item?.NDWI?.toFixed(2) || 0)
    },
    {
      name: 'Mean',
      data: modalProperties?.meanset.map((item) => item?.NDWI?.toFixed(2) || 0)
    },
    {
      name: 'Max',
      data: modalProperties?.maxset.map((item) => item?.NDWI?.toFixed(2) || 0)
    },
    {
      name: 'SD',
      data: modalProperties?.stdset.map((item) => item?.NDWI?.toFixed(2) || 0)
    },
  ]

  const NDVI = [
    {
      name: 'Min',
      data: modalProperties?.minset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
    {
      name: 'Mean',
      data: modalProperties?.meanset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
    {
      name: 'Max',
      data: modalProperties?.maxset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
    {
      name: 'SD',
      data: modalProperties?.stdset.map((item) => item?.NDVI?.toFixed(2) || 0)
    },
  ]


  return (
    <div className='border w-full h-full bg-white border-gray-300 shadow p-5'>
      <div className='w-full h-full border border-gray-300'>

        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          className='!w-full' open={Boolean(modalProperties)} onClose={() => setModalProperties(null)}>
          <DialogTitle>Spectral Profiles</DialogTitle>
          <DialogContent className='!w-full'>
            <Box className='w-full flex justify-center'>
              <Tabs
                className='w-full'
                items={[
                  // {
                  //   key: "CIG",
                  //   label: "CIG",
                  //   children: <ReactApexChart options={options} series={CIG} type="area" height={350} />
                  // },
                  // {
                  //   key: "CIRedEdge",
                  //   label: "CIRedEdge",
                  //   children: <ReactApexChart options={options} series={CIRedEdge} type="area" height={350} />
                  // },
                  {
                    key: "LSWI",
                    label: "LSWI",
                    children: <ReactApexChart options={options} series={LSWI} type="area" height={350} />
                  },
                  {
                    key: "NDRE",
                    label: "NDRE",
                    children: <ReactApexChart options={options} series={NDRE} type="area" height={350} />
                  },
                  {
                    key: "NDVI",
                    label: "NDVI",
                    children: <ReactApexChart options={options} series={NDVI} type="area" height={350} />
                  },
                  {
                    key: "NDWI",
                    label: "NDWI",
                    children: <ReactApexChart options={options} series={NDWI} type="area" height={350} />
                  },
                ]}
              />
            </Box>
          </DialogContent>
        </Dialog>
        <MapContainer
          center={[28.576592977786234, 77.31433377113422]}
          zoom={2}
          style={{ height: '100%', width: '100%' }}
        >
          <GeoJSON key={JSON.stringify(geoJson)} data={geoJson} onEachFeature={(feature, layer) => {
            layer.addEventListener("click", (event) => setModalProperties(event.target.feature.properties.profiles))
          }} />
          <TileLayer
            url="//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            maxZoom={21}
            minZoom={4}
            minZoomIsDrawing={18}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          <RasterLayer bounds={bounds} rasterLayerUrl={rasterLayerUrl} />
        </MapContainer>
      </div>
    </div>
  );
};

const RasterLayer = ({ bounds, rasterLayerUrl }) => {
  const map = useMap();

  useEffect(() => {
    if (map && map.getZoom() !== 1) {
      if (bounds) {
        map.fitBounds(bounds)
      }
      if (rasterLayerUrl) {
        let layer = L.tileLayer(rasterLayerUrl, { tms: false })
        layer.addTo(map)
      }
    }

  }, [map, rasterLayerUrl, bounds]);

  return
};

export default Map;
