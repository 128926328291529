import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import DragUpload from 'components/Form/DragUpload';
import Select from 'react-select';
import { useGetDistrictMasterQuery, useGetStateMasterQuery } from 'services/masterDataApiSlice';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined, ReloadOutlined } from '@ant-design/icons';
import FileUpload from 'components/Form/FileUpload';


const SpectralProfilingTask = () => {

  const [selectedState, setSelectedState] = useState("09")

  const {
    data: statesData,
    isFetching: isStatesDataLoading,
    isSuccess: isStatesDataSuccess,
    isError: isStatesDataError,
    error: statesDataError
  } = useGetStateMasterQuery({}, { refetchOnMountOrArgChange: true })

  const {
    data: districtsData,
    isFetching: isDistrictsDataLoading,
    isSuccess: isDistrictsDataSuccess,
    isError: isDistrictsDataError,
    error: districtDistrictDataError
  } = useGetDistrictMasterQuery({ selectedState }, { skip: selectedState === null })

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [statesList, setStatesList] = useState([])
  const [districtsList, setDistrictsList] = useState([])
  const [selectedLocation, setSelectedLocation] = useState({
    taskName: "Spectral Profiling",
    stateName: "UTTAR PRADESH",
    stateCode: "09",
    districtName: "",
    districtCode: "",
    cropName: "",
    file: null
  })

  useEffect(() => {
    setStatesList(statesData && statesData.data.map((state) => {
      return {
        label: state.state_name,
        value: state.state_code
      }
    }))
  }, [statesData])

  useEffect(() => {
    setDistrictsList(isDistrictsDataSuccess && districtsData.data.map((district) => {
      return {
        label: district.district_name,
        value: district.district_code,
      }
    }))
  }, [districtsData, selectedState])

  const handleSubmit = () => {
    setIsSubmitLoading(true)
    const formData = new FormData()
    
    formData.append("task_name", selectedLocation.taskName)
    formData.append("state_name", selectedLocation.stateName)
    formData.append("district_id", selectedLocation.districtCode)
    formData.append("district_name", selectedLocation.districtName)
    formData.append("crop_name", selectedLocation.cropName)
    formData.append("file", selectedLocation.file[0])


    fetch("https://datapipeline-api.agrani.tech/create_task", {
      method: 'POST',
      body: formData
    }).then((resp) => {
      console.log(resp)
      setIsSubmitLoading(false)
      window.location.reload()
    })
  }

  useEffect(() => {
    console.log(selectedLocation)
  }, [selectedLocation])

  return (
    <Box className="w-full flex flex-col justify-center  gap-10">
      <Box className="w-full justify-between flex gap-8">
        <Select
          placeholder="Select State"
          isLoading={isDistrictsDataLoading}
          isDisabled={true}
          value={selectedLocation.stateName ? ({ label: selectedLocation.stateName, value: selectedLocation.stateCode }) : null}
          onChange={(e) => { setSelectedState(e.value); setSelectedLocation({ ...selectedLocation, stateName: e.label, stateCode: e.value }) }}
          className="w-full"
          options={
            statesData && statesData.data?.map((state) => {
              return {
                label: state.state_name,
                value: state.state_code
              }
            }).sort((a, b) => (a.label > b.label) ? 1 : -1)
          }
        />

        <Select
          placeholder="Select District"
          className="w-full"
          isDisabled={isDistrictsDataLoading}
          isLoading={isDistrictsDataLoading}
          value={selectedLocation.districtName ? ({ label: selectedLocation.districtName, value: selectedLocation.districtCode }) : null}
          onChange={(e) => { setSelectedLocation({ ...selectedLocation, districtName: e.label, districtCode: e.value }) }}
          options={
            districtsData && districtsData.data?.map((district) => {
              return {
                label: district.district_name,
                value: district.district_code
              }
            }).sort((a, b) => (a.label > b.label) ? 1 : -1)
          }
        />
        {/* <Select
          placeholder="Select Crop"
          className="w-full"
          onChange={(e) => { }}
          options={[
            {
              label: "Paddy",
              value: "Paddy"
            },
            {
              label: "Wheat",
              value: "Wheat"
            }
          ]}
        /> */}
      </Box>
      <FileUpload setFiles={(files)=> setSelectedLocation({...selectedLocation, file: files})} />
      <Box className="w-full flex justify-end">
        <Button className='!bg-orange-500  h-10' disableElevation variant="contained" onClick={handleSubmit}>
          Submit {isSubmitLoading ? <ReloadOutlined spin={isSubmitLoading} size={"small"} /> : null}
        </Button>
      </Box>
    </Box>
  )
}

export default SpectralProfilingTask